import * as React from "react";
import Layout from "../../components/layout";
import useStores from "../../hooks/use-stores";
// this page is example for sending post request body

const SellEditPage = () => {
  const { propertyStore } = useStores();
  const [saleStatus, setSaleStatus] = React.useState<string>("");
  const getFilteredProperty = () => {
    propertyStore.getFilteredProperty({
      saleStatus,
      propertyByNature: 0,
    })
  }

  return (
    <Layout>
      <h6>
        Current Location: Housing Stock - Sell - <span className="font-semibold">Edit</span>
      </h6>
      {/* <input type="text" placeholder="eg: Fabulous Apartment in Convenient Central Location"
            value={title} onChange={(event) => setTitle(event.target.value)} /> */}
      <input value={saleStatus} onChange={(event) => setSaleStatus(event.target.value)} />
      <button onClick={getFilteredProperty}>Submit</button>
    </Layout>
  );
};

export default SellEditPage;

export const Head = () => <title>Real estate | Sell | Edit</title>;